<template>
  <div class="d-flex flex-column">
    <b-table
      id="employee-monthly-attendance-table"
      ref="employee-monthly-attendance-table"
      table-class="table-head-custom table-vertical-center"
      :responsive="true"
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
    >
      <!-- begin:header template    -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end:header template    -->

      <!-- begin: cell templates      -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">
          {{ data.index + 1 }}
        </span>
      </template>

      <template v-slot:cell(employee)="data">
        <CommonEmployeeSummary :item="data.item"></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(present)="data">
        <AppSmallLightButton>
          {{ data.item.present_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(absent)="data">
        <AppSmallLightButton>
          {{ data.item.absent_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(leave)="data">
        <AppSmallLightButton
          @button-clicked="$emit('leave-count-clicked', data.item)"
        >
          {{ data.item.leave_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(away)="data">
        <AppSmallLightButton
          @button-clicked="$emit('away-count-clicked', data.item)"
        >
          {{ data.item.away_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(holiday)="data">
        <AppSmallLightButton>
          {{ data.item.holiday_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(offdays)="data">
        <AppSmallLightButton>
          {{ data.item.offdays_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-right">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="$emit('showIndividualAttendance', data)"
            v-b-tooltip.hover="$t('ATTENDANCE.HOVER.MONTHLY')"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
            </span>
          </button>
        </div>
      </template>

      <!-- end: cell templates      -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        class="pt-5"
        @change="$emit('changePage', $event)"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-monthly-attendance-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppSmallLightButton from "@/modules/core/components/button/AppSmallLightButton";
export default {
  name: "AttendancePageMonthlyTable",
  components: {
    AppSmallLightButton,
    AppLoader,
    CommonEmployeeSummary,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: Array,
    },
    pagination: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "employee", label: i18n.t("EMPLOYEE.HEADER") },
        { key: "present", label: "Present" },
        { key: "absent", label: "Absent" },
        { key: "leave", label: "On Leave" },
        { key: "away", label: "Away" },
        { key: "holiday", label: "Holiday" },
        { key: "offdays", label: "Offday" },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
    };
  },
};
</script>

<style scoped></style>
