<template>
  <div>
    <!-- begin: Table Controls    -->
    <div class="d-flex justify-content-end align-content-center">
      <!--      Button Start-->
      <div class="my-auto">
        <b-button
          class="font-weight-bolder"
          variant="light-primary"
          @click="$router.push({ name: 'employeeMonthlyAttendanceExport' })"
          >Details
        </b-button>
      </div>
      <!--      Button End-->
    </div>

    <!-- end: Table Controls    -->

    <AttendancePageMonthlyTable
      :items="list"
      :pagination="meta"
      :is-busy="isBusyStore"
      @changePage="changePage"
      @showIndividualAttendance="showEmployeeAttendance"
      @away-count-clicked="showAwayModal"
      @leave-count-clicked="showLeaveModal"
    ></AttendancePageMonthlyTable>

    <EmployeeIndividualAwayModal
      :aways="aways"
      :is-busy="isBusy"
      :pagination="awayPagination"
      @page-change="handleAwayChange"
    ></EmployeeIndividualAwayModal>

    <EmployeeIndividualLeaveModal
      :leaves="leaves"
      :is-busy="isBusy"
      :pagination="leavePagination"
      @page-change="handleLeavePage"
    ></EmployeeIndividualLeaveModal>

    <EmployeeAttendanceMonthlyModal></EmployeeAttendanceMonthlyModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeAttendanceMonthlyModal from "@/modules/company/components/attendance/monthly/EmployeeAttendanceMonthlyModal";
import dayjs from "dayjs";
import AttendancePageMonthlyTable from "@/modules/company/components/attendance/monthly/AttendancePageMonthlyTable";
import EmployeeIndividualAwayModal from "@/modules/company/components/attendance/common/EmployeeIndividualAwayModal";
import EmployeeIndividualLeaveModal from "@/modules/company/components/attendance/common/EmployeeIndividualLeaveModal";

export default {
  name: "EmployeeMonthlyAttendance",
  components: {
    EmployeeIndividualLeaveModal,
    EmployeeIndividualAwayModal,
    AttendancePageMonthlyTable,
    EmployeeAttendanceMonthlyModal,
  },
  props: ["reload"],
  data() {
    return {
      isBusy: false,
      monthSelection: 0,
      yearSelection: 0,
    };
  },

  mounted() {
    this.monthSelection = dayjs().month();
    this.yearSelection = dayjs().year();
    this.fetch();
  },

  methods: {
    fetch() {
      console.log("Fetching monthly list");
      this.isBusy = true;
      this.$store
        .dispatch("fetchEmployeeMonthlyAttendanceList")
        .finally(() => (this.isBusy = false));
    },

    rowSelect(row) {
      this.openModal(row);
    },

    showEmployeeAttendance(data) {
      console.log("Selected employee, ", data);
      this.openModal(data.item);
    },

    openModal(data) {
      this.$store.dispatch("assignIndividualEmployeeAttendance", data);

      const formatted = dayjs(this.dateSelected).toDate();
      console.log("date taken:", formatted);
      this.$store.dispatch("assignIndividualEmployeeAttendanceDate", formatted);

      this.$bvModal.show("employee-page-clock-record-modal");
    },

    refresh() {
      this.fetch();
    },

    changePage(value) {
      console.log("Setting val", this.meta.current_page);
      this.$store.commit("setEmployeeMonthlyAttendancePage", value);
      this.fetch();
    },

    /**
     * Show employee leave modal.
     *
     * @param data
     */
    showAwayModal(data) {
      console.log(data);

      const currentDate = this.defineDateFilter();
      console.log("Date filter is", currentDate);

      this.$store.dispatch("changeIndividualEmployeeAwayListFilter", {
        date: currentDate,
        employeeId: data.id,
      });

      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualEmployeeAwayMonthlyList")
        .finally(() => (this.isBusy = false));
      this.$bvModal.show("school-employee-away-modal");
    },

    /**
     *
     */
    handleAwayChange(page) {
      console.log("Page : ", page);

      this.$store.commit("setIndividualEmployeeAwayListPage", page);
      this.$store.dispatch("fetchIndividualEmployeeAwayMonthlyList");
    },

    /**
     * Show employee leave modal.
     *
     * @param data
     */
    showLeaveModal(data) {
      console.log(data);

      console.log("Item:", this.list);

      const currentDate = this.defineDateFilter();
      console.log("Date filter is", currentDate);

      this.$store.dispatch("changeIndividualEmployeeLeaveListFilter", {
        date: currentDate,
        employeeId: data.id,
      });

      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualEmployeeLeaveMonthlyList")
        .finally(() => (this.isBusy = false));
      this.$bvModal.show("school-employee-leave-modal");
    },

    /**
     * Handle employee individual leave page change event.
     *
     * @param page
     */
    handleLeavePage(page) {
      console.log(page);

      this.$store.commit("setIndividualEmployeeLeaveListPage", page);
      this.$store.dispatch("fetchIndividualEmployeeLeaveMonthlyList");
    },

    navigatePrint() {
      this.fetchCompany();
      this.$router.push({ name: "employeeMonthlyAttendancePdf" });
    },

    fetchCompany() {
      this.$store.dispatch("fetchCompanyProfileForm").then(() => {});
    },
  },

  watch: {
    reload() {
      console.log("Reload Table!");
      this.fetch();
    },
  },

  computed: {
    ...mapGetters({
      list: "getEmployeeMonthlyAttendanceList",
      meta: "getEmployeeMonthlyAttendanceMeta",
      leaves: "getIndividualEmployeeLeaveList",
      aways: "getIndividualEmployeeAwayList",
      leavePagination: "getIndividualEmployeeLeaveListPagination",
      awayPagination: "getIndividualEmployeeAwayListPagination",
      dateSelected: "getEmployeeMonthlyAttendanceDate",
      isBusyStore: "getEmployeeMonthlyAttendanceBusy",
    }),

    years() {
      const now = dayjs();
      let years = [];
      for (let i = 0; i < 5; i++) {
        let sYear = now.add(i, "year");
        years.push({
          value: sYear.format("YYYY"),
          text: sYear.format("YYYY"),
        });
      }

      return years;
    },

    months() {
      require("dayjs/locale/en");
      require("dayjs/locale/ms");
      const localeData = require("dayjs/plugin/localeData");
      dayjs.extend(localeData);
      dayjs.locale(this.$i18n.locale);

      console.log(dayjs.months(), this.$i18n.locale);

      const months = dayjs.months().map((month, key) => {
        return {
          text: month,
          value: key,
        };
      });
      console.log(months);
      return months;
    },
  },
};
</script>

<style scoped></style>
